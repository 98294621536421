<template>
  <Dialog
    :header="
      editNotificationIndex == null
        ? $t('settings.createNotification')
        : $t('settings.editNotifiation')
    "
    v-model:visible="viewDialog"
    :draggable="false"
    :modal="true"
    :style="{ width: '70vw' }"
  >
    <div class="grid grid-nogutter vertical-container align-items-center">
      <div class="col-12 md:col-3 pb-0">
        <label for="notification">{{ $t("settings.notification") }}*:</label>
      </div>
      <div class="field col-12 md:col-6 pt-0 md:pt-3 p-fluid mb-2">
        <InputText
          id="notification"
          :class="{
            'p-invalid':
              v$.notificationObject.notification.$invalid && submitted,
          }"
          aria-describedby="country-error"
          v-model="v$.notificationObject.notification.$model"
          :showIcon="true"
        />
      </div>
      <ul
        v-if="v$.notificationObject.notification.$error && submitted"
        class="p-error text-xs col-12 md:col-9 col-offset-0 md:col-offset-3 pt-0 pl-3 mt-0"
      >
        <li
          v-for="(error, index) of v$.notificationObject.notification.$errors"
          :key="index"
        >
          {{ error.$message.replace("[value]", $t("settings.notification")) }}
        </li>
      </ul>
    </div>

    <NotificationContent
      v-for="(content, index) in notificationObject.content"
      :key="index"
      :contentValue="content"
      :submittedValue="submitted"
      :indexValue="index"
      @deleteNotificationContent="deleteNotificationContent"
    ></NotificationContent>
    <div class="flex justify-content-end my-1">
      <Button :label="$t('settings.addContent')" @click="addContent"></Button>
    </div>
    <div class="grid">
      <div class="col">
        <span>* {{ $t("global.mandatoryFields") }}</span>
      </div>
    </div>

    <template #footer>
      <Button
        :label="
          editNotificationIndex == null
            ? $t('global.create')
            : $t('global.save')
        "
        class="p-button-success"
        @click="create"
        autofocus
        :loading="loadingButton"
        iconPos="right"
      />
      <Button
        :label="$t('global.close')"
        @click="close()"
        class="p-button-danger"
      />
    </template>
  </Dialog>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { required } from "@/utilities/i18n-validators";
import NotificationContent from "./NotificationContent.vue";
import { mapGetters } from "vuex";
export default {
  name: "Add edit notification",
  components: {
    NotificationContent,
  },
  props: ["display", "editNotificationIndex"],
  emits: ["closeAddEditNotification"],
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      viewDialog: false,
      submitted: false,
      loadingButton: false,
      notificationObject: {
        notification: "",
        content: [
          {
            roles: [],
            severity: null,
            title: "",
            message: "",
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters(["notificationsObject"]),
  },
  watch: {
    display(val) {
      this.viewDialog = val;
      // console.log(this.caseValue);
    },
    viewDialog(val) {
      if (val == false) {
        this.close();
        this.submitted = false;
        this.v$.$reset();
      } else {
        console.log(this.editNotificationIndex);
        if (this.editNotificationIndex != null) {
          this.notificationObject = JSON.parse(
            JSON.stringify(this.notificationsObject[this.editNotificationIndex])
          );
        }
      }
    },
  },
  validations: {
    notificationObject: {
      notification: {
        required,
      },
      content: {
        required,
      },
    },
  },
  async mounted() {
    await this.$store.dispatch("roles");
  },
  methods: {
    clearNotification() {
      this.notificationObject = {
        notification: "",
        content: [
          {
            roles: [],
            severity: null,
            title: "",
            message: "",
          },
        ],
      };
    },
    async create() {
      this.submitted = true;
      const result = await this.v$.$validate();
      if (result) {
        const tempNotifications = [...this.notificationsObject];
        if (this.editNotificationIndex != null) {
          tempNotifications[this.editNotificationIndex] =
            this.notificationObject;
          this.$store.commit("notificationsObject", tempNotifications);
          this.close();
        } else {
          this.close(this.notificationObject);
        }
      }
    },
    close(newNotification = null) {
      this.$emit("closeAddEditNotification", newNotification);
      this.clearNotification();
    },
    addContent() {
      this.notificationObject.content.push({
        roles: [],
        severity: null,
        title: "",
        message: "",
      });
    },
    deleteNotificationContent(index) {
      if (this.notificationObject.content.length > 1) {
        this.notificationObject.content.splice(index, 1);
      } else {
        this.$confirm.require({
          message: this.$t("settings.oneNotification"),
          header: this.$t("global.warning"),
          icon: "pi pi-info-circle",
          acceptLabel: this.$t("global.ok"),
          rejectClass: "hidden",
        });
      }
    },
  },
};
</script>
