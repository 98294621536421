<template>
  <AddEditNotification
    :display="showAddEditNotification"
    :editNotificationIndex="editNotificationIndex"
    @closeAddEditNotification="closeAddEditNotification"
  ></AddEditNotification>
  <BulkImport
    :display="showBulkImport"
    :type="'notification'"
    @closeImport="closeBulkImport"
    @importData="importData"
  ></BulkImport>
  <Card>
    <template #title>
      <div class="grid">
        <div class="col-6 md:col-2">
          <h4>{{ $t("settings.notifications") }}</h4>
        </div>
        <div class="text-center text-right col-6 md:col-2">
          <Button
            v-if="!isMobile"
            :label="$t('global.export')"
            @click="exportCSV"
          ></Button>
        </div>
        <div class="col-6 md:col-2 text-right">
          <Button
            :label="$t('settings.bulkDownload')"
            @click="bulkExport"
          ></Button>
        </div>
        <div class="col-6 md:col-2 text-right">
          <Button
            :label="$t('settings.bulkAddition')"
            @click="bulkNotifications"
          ></Button>
        </div>
        <div class="col-6 md:col-2 text-left md:text-right">
          <Button
            :label="$t('settings.createNotification')"
            @click="createNotification"
            class="mr-3"
          ></Button>
        </div>
        <div class="col-6 md:col-2 text-right">
          <Button :label="$t('global.saveJson')" @click="exportJson"></Button>
        </div>
      </div>
    </template>
    <template #content>
      <!-- <Column :header="$t('global.actions')">
          <template #body="{ data }">
            <Button
              class="p-button-text p-button-rounded"
              icon="mdi mdi-pencil mdi-24px"
              @click="editNotification(data)"
            />
          </template>
        </Column> -->
      <DataTable
        :value="notificationsValues"
        v-model:expandedRows="expandedRows"
        dataKey="notification"
        :rows="10"
        :rowsPerPageOptions="[10, 25, 50]"
        :currentPageReportTemplate="$tableString"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :paginator="true"
        :loading="loading"
        ref="notificationsTable"
        @page="changePage"
      >
        <Column :expander="true" headerStyle="width: 3rem" />
        <Column
          field="notification"
          :header="$t('settings.name')"
          sortable
        ></Column>
        <Column :header="$t('global.actions')">
          <template #body="{ data, index }">
            <div class="flex justify-content-evenly align-items-center">
              <Button
                class="p-button-text mdi mdi-24px mdi-pencil"
                @click="editNotification(index)"
              />
              <Button
                icon="mdi mdi-close-circle mdi-24px"
                class="p-button-rounded p-button-text p-button-text"
                @click="deleteNotification(data, index)"
              />
            </div>
          </template>
        </Column>
        <template #expansion="slotProps">
          <div>
            <DataTable :value="slotProps.data.content" showGridlines>
              <Column
                class="bg-gray-200"
                field="roles"
                :header="$t('settings.roles')"
              >
                <template #body="{ data }">
                  <Chip
                    v-for="(role, index) in data.roles"
                    :key="index"
                    :label="role"
                    class="mr-2 mb-2"
                  />
                </template>
              </Column>
              <Column
                class="bg-gray-200"
                field="severity"
                :header="$t('settings.severity')"
                sortable
              ></Column>
              <Column
                class="bg-gray-200"
                field="title"
                :header="$t('settings.title')"
                sortable
              ></Column>
              <Column
                class="bg-gray-200"
                field="message"
                :header="$t('settings.message')"
                sortable
              >
                <template #body="{ data }">
                  <div v-html="data.message"></div>
                </template>
              </Column>
            </DataTable>
          </div>
        </template>
      </DataTable>
    </template>
  </Card>
</template>

<script>
import { mapGetters } from "vuex";
import AddEditNotification from "./AddEditNotification.vue";
import BulkImport from "../BulkImport.vue";

export default {
  name: "Notifications",
  components: {
    AddEditNotification,
    BulkImport,
  },
  data() {
    return {
      expandedRows: [],
      showAddEditNotification: false,
      loading: false,
      showBulkImport: false,
      notificationsValues: [],
      editNotificationIndex: null,
      currentPage: 0,
      currentRows: null,
    };
  },
  async mounted() {
    this.loading = true;
    await this.$store.dispatch("notificationsObject");
    this.notificationsValues = this.notificationsObject;
    this.loading = false;
  },
  computed: {
    ...mapGetters(["notificationsObject", "isMobile"]),
  },
  methods: {
    changePage(data) {
      console.log(data);
      this.currentPage = data.page;
      this.currentRows = data.rows;
    },
    createNotification() {
      this.showAddEditNotification = true;
    },
    editNotification(index) {
      this.showAddEditNotification = true;
      this.editNotificationIndex = index + this.currentPage * this.currentRows;
    },
    closeAddEditNotification(newNotification) {
      this.showAddEditNotification = false;
      this.editNotificationIndex = null;
      if (newNotification) {
        this.notificationsValues.push(newNotification);
        this.$store.commit("notificationsObject", this.notificationsValues);
      } else {
        this.notificationsValues = this.notificationsObject;
      }
    },
    bulkNotifications() {
      this.showBulkImport = true;
    },
    closeBulkImport() {
      this.showBulkImport = false;
    },
    async bulkExport() {
      await this.$store.dispatch("bulkExport", "notifications");
    },
    importData() {},
    async exportJson() {
      this.$store.dispatch("exportJson", {
        name: "notifications",
        json: this.notificationsValues,
      });
    },
    deleteNotification(notificationObject, notificationIndex) {
      this.$confirm.require({
        message: this.$t("settings.deleteNotification", [
          notificationObject.notification,
        ]),
        header: this.$t("settings.deleteConfirmation"),
        icon: "pi pi-info-circle",
        acceptClass: "p-button-danger",
        acceptLabel: this.$t("global.yes"),
        rejectLabel: this.$t("global.no"),
        accept: () => {
          this.notificationsValues.splice(
            notificationIndex + this.currentPage * this.currentRows,
            1
          );
          this.$store.commit("notificationsObject", this.notificationsValues);
        },
      });
    },
    exportCSV() {
      this.$refs.notificationsTable.exportCSV();
    },
  },
};
</script>
