<template>
  <div
    class="grid grid-nogutter border-1 my-3 p-3"
    style="border-color: #cccccc"
  >
    <div class="col-12 lg:col-5">
      <div class="grid grid-nogutter vertical-container align-items-center">
        <div class="col-12 lg:col-3 pb-0">
          <label for="title">{{ $t("settings.title") }}*:</label>
        </div>
        <div class="field col-11 lg:col-8 pt-0 md:pt-3 p-fluid mb-2">
          <InputText
            id="title"
            v-model="v$.content.title.$model"
            :class="{
              'p-invalid': v$.content.title.$invalid && submitted,
            }"
          >
          </InputText>
        </div>
        <div class="col-1 text-center" v-if="screenWidth < 1021">
          <Button
            icon="mdi mdi-close-circle mdi-18px"
            class="p-button-rounded p-button-secondary p-button-text"
            @click="deleteNotificationContent(indexValue)"
            
          />
        </div>
        <ul
          v-if="v$.content.title.$error && submitted"
          class="p-error text-xs col-12 lg:col-9 col-offset-0 lg:col-offset-3 pt-0 pl-3 mt-0"
        >
          <li v-for="(error, index) of v$.content.title.$errors" :key="index">
            {{ error.$message.replace("[value]", $t("settings.title")) }}
          </li>
        </ul>
      </div>
    </div>
    <!-- <div class="col-1 text-center align-self-center" v-if="screenWidth < 1021">
    </div> -->
    <div class="col-11 lg:col-6">
      <div class="grid grid-nogutter vertical-container align-items-center">
        <div class="col-12 lg:col-3 pb-0">
          <label for="severity">{{ $t("settings.severity") }}*:</label>
        </div>
        <div class="field col-12 lg:col-9 pt-0 md:pt-3 p-fluid mb-2">
          <Dropdown
            id="severity"
            :class="{
              'p-invalid': v$.content.severity.$invalid && submitted,
            }"
            v-model="v$.content.severity.$model"
            :options="severityLevels"
          >
            <template #option="slotProps">
              <div
                :style="`background-color: ${
                  severityColors(slotProps.option).color
                } !important`"
                class="h-2rem flex justify-content-center align-items-center"
              >
                {{ severityColors(slotProps.option).word }}
              </div>
            </template>
            <template #value="slotProps">
              <div
                v-if="slotProps.value"
                :style="`background-color: ${
                  severityColors(slotProps.value).color
                } !important`"
                class="h-2rem flex justify-content-center align-items-center"
              >
                {{ severityColors(slotProps.value).word }}
              </div>
              <div v-else>{{ $t("settings.chooseSeverity") }}</div>
            </template>
          </Dropdown>
        </div>
        <ul
          v-if="v$.content.severity.$error && submitted"
          class="p-error text-xs col-12 lg:col-9 col-offset-0 lg:col-offset-3 pt-0 pl-3 mt-0"
        >
          <li
            v-for="(error, index) of v$.content.severity.$errors"
            :key="index"
          >
            {{ error.$message.replace("[value]", $t("settings.severity")) }}
          </li>
        </ul>
      </div>
    </div>
    <div class="col-1 text-center align-self-center" v-if="screenWidth >= 1021">
      <Button
        icon="mdi mdi-close-circle mdi-18px"
        class="p-button-rounded p-button-secondary p-button-text"
        @click="deleteNotificationContent(indexValue)"
        
      />
    </div>
    <div class="col-11 lg:col-5">
      <div class="grid grid-nogutter vertical-container align-items-center">
        <div class="col-12 lg:col-3 pb-0">
          <label for="roles">{{ $t("settings.roles") }}*:</label>
        </div>
        <div class="field col-12 lg:col-8 pt-0 md:pt-3 p-fluid mb-2">
          <MultiSelect
            id="roles"
            v-model="v$.content.roles.$model"
            :options="roles"
            :class="{
              'p-invalid': v$.content.roles.$invalid && submitted,
            }"
            display="chip"
            optionLabel="name"
            optionValue="name"
          >
          </MultiSelect>
        </div>
        <ul
          v-if="v$.content.roles.$error && submitted"
          class="p-error text-xs col-12 lg:col-9 col-offset-0 lg:col-offset-3 pt-0 pl-3 mt-0"
        >
          <li v-for="(error, index) of v$.content.roles.$errors" :key="index">
            {{ error.$message.replace("[value]", $t("settings.roles")) }}
          </li>
        </ul>
      </div>
    </div>
    <div class="col-11 lg:col-6">
      <div class="grid grid-nogutter vertical-container align-items-center">
        <div class="col-12 lg:col-3 pb-0">
          <label for="message">{{ $t("settings.message") }}*:</label>
        </div>
        <div class="field col-12 lg:col-9 pt-0 md:pt-3 p-fluid mb-2">
          <Textarea
            id="message"
            v-model="v$.content.message.$model"
            :class="{
              'p-invalid': v$.content.message.$invalid && submitted,
            }"
            rows="6"
            cols="30"
          />
        </div>
        <ul
          v-if="v$.content.message.$error && submitted"
          class="p-error text-xs col-12 lg:col-9 col-offset-0 lg:col-offset-3 pt-0 pl-3 mt-0"
        >
          <li v-for="(error, index) of v$.content.message.$errors" :key="index">
            {{ error.$message.replace("[value]", $t("settings.message")) }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { required } from "@/utilities/i18n-validators";
import { mapGetters } from "vuex";
export default {
  name: "Notification content",
  setup: () => ({ v$: useVuelidate() }),
  props: ["contentValue", "submittedValue", "indexValue"],
  data() {
    return {
      severityLevels: [1, 2, 3, 4],
    };
  },
  validations: {
    content: {
      roles: {
        required,
      },
      severity: {
        required,
      },
      title: {
        required,
      },
      message: {
        required,
      },
    },
  },
  computed: {
    ...mapGetters(["roles", "screenWidth"]),
    content() {
      return this.contentValue;
    },
    submitted() {
      return this.submittedValue;
    },
  },
  async mounted() {
    if (this.submitted) {
      await this.v$.$validate();
    }
  },
  methods: {
    severityColors(severity) {
      const colors = [
        {
          value: 1,
          color: "#C8E6C9",
          word: "Success",
        },
        {
          value: 2,
          color: "#B3E5FC",
          word: "Info",
        },
        {
          value: 3,
          color: "#FFECB3",
          word: "Warning",
        },
        {
          value: 4,
          color: "#FFCDD2",
          word: "Error",
        },
      ];
      return colors.filter((el) => {
        return el.value == severity;
      })[0];
    },
    deleteNotificationContent(index) {
      this.$emit("deleteNotificationContent", index);
    },
  },
};
</script>
